import VueDropzone from 'vue2-dropzone'
import excelParser from '../../../../services/excel-parser'
import LocationModal from './location-modal'
import {COMMUNICATION_TYPE, PRODUCT_DISRUPTION_TYPES } from '../../../../misc/commons'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import _ from 'lodash'
export default {
    components: {
        VueDropzone,
        LocationModal
    },
    props: ['communicationId', 'recallDisabled', 'infoData','communicationType'],
    data() {
        return {
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            },
            item: {},
            listHeader: [],
            isFileSelect: false,
            locationModalKey: 0,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            productDisruptionTypes: PRODUCT_DISRUPTION_TYPES,
            coveredListSearchQuery: {},
            locationsCoveredList: [],
            totalLocationsCovered: 0,
            validCustomerList: [],
            totalValidCustomer: 0,
            invalidCustomerList: [],
            totalInvalidCustomer: 0,
            locationsEmailedList: [],
            totalLocationsEmailed: 0,
            notCoveredListSearchQuery: {},
            locationsNotCoveredList: [],
            totalLocationsNotCovered: 0,

            unRecognisedListSearchQuery: {},
            locationsUnRecognisedList: [],
            totalLocationsUnRecognised: 0,
            info: {},

            header: [
                {
                    label: 'Account Name',
                    name: 'account_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Email Address',
                    name: 'email_address',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'First Name',
                    name: 'first_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Last Name',
                    name: 'last_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Account Number',
                    name: 'account_number',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'IDN',
                    name: 'idn',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],
        }
    },
    mounted() {
        this.info = this.infoData
    },
    methods: {
        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                location_id: null,
                file: null
            }

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xls','xlsx'].includes(ext[ext.length - 1])) {
                    this.item.file = file
                    try {
                        let formData = new FormData()
                        formData.append('file', this.item.file)
                        excelParser.getExcelHeaders(formData).then(res => {
                            if (!res.error) {
                                this.listHeader = res.data.d.map(x => { return { value: x, text: x } })
                                this.isFileSelect = true
                            }
                        })
                    } catch (e) {console.log(e) }
                } else {
                    this._showToast('Only accept xls, xlsx file format', { variant: 'danger' })
                }
            }
        },
        save() {
            this.$refs['observer'].validate().then(isValid => {
                if (isValid) {
                    let formData = new FormData()
                    formData.append('id', this.communicationId)
                    formData.append('accountname', this.item.account_name)
                    formData.append('emailaddress', this.item.email_address)
                    formData.append('accountnumber', this.item.account_number)
                    formData.append('firstname', this.item.first_name)
                    formData.append('lastname', this.item.last_name)
                    formData.append('address1', this.item.address1)
                    formData.append('address2', this.item.address2)
                    formData.append('city', this.item.city)
                    formData.append('state', this.item.state)
                    formData.append('zipcode', this.item.zipcode)
                    formData.append('idn', this.item.idn)
                    formData.append('file', this.item.file)
                    formData.append('communicationType', this.communicationType)
                    supplierRecallService.saveCommunicationStep3Customers(formData).then(res => {
                        if (!res.error) {
                            this.isFileSelect = false
                            this.item = null
                            this._showToast('Successfully uploaded')
                            this.showResult('fileupload',this.communicationId, res.data.d)
                        }
                    })
                }
            })
        },
        selectAll(){
            this.fetchAllLocationList()
        },
        fetchAllLocationList() {
            supplierRecallService.locationsBycommunicationId({
                ...this.coveredListSearchQuery,
                communicationId: this.communicationId
            }).then(resp => {
                this.showResult('selectAll',this.communicationId, 0)
            })
        },
        showResult(mode, communicationId, fileId) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerender(mode, communicationId, fileId)
            })
        },

        onCompleted() {
            this.fetchUnRecognisedList()
            this.fetchNotCoveredList()
            this.$parent.currentStep = 0
            this.$parent.currentStep = 3
        },

        fetchCoveredList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'VALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsCoveredList = resp.data.d.c
                    this.totalLocationsCovered = resp.data.d.t
                }
            })
        },
        ValidCustomersList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'VALID'
            }).then(resp => {
                if (!resp.error) {
                    this.validCustomerList = resp.data.d.c
                    this.totalValidCustomer = resp.data.d.t
                }
            })
        },
        InValidCustomersList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'INVALID'
            }).then(resp => {
                if (!resp.error) {
                    this.invalidCustomerList = resp.data.d.c
                    this.totalInvalidCustomer = resp.data.d.t
                }
            })
        },
        fetchEmailedList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.coveredListSearchQuery ? (this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'EMAILED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsEmailedList = resp.data.d.c
                    this.totalLocationsEmailed = resp.data.d.t
                }
            })
        },
        fetchNotCoveredList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.notCoveredListSearchQuery,
                sortField: this.notCoveredListSearchQuery ? (this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.notCoveredListSearchQuery ? (this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'INVALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsNotCoveredList = resp.data.d.c
                    this.totalLocationsNotCovered = resp.data.d.t
                }
            })
        },
        fetchUnRecognisedList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.unRecognisedListSearchQuery,
                sortField: this.unRecognisedListSearchQuery ? (this.unRecognisedListSearchQuery.sort[0] ? this.unRecognisedListSearchQuery.sort[0].name : null) : null,
                sortDirection: this.unRecognisedListSearchQuery ? (this.unRecognisedListSearchQuery.sort[0] ? this.unRecognisedListSearchQuery.sort[0].order : null) : null,
                communicationId: this.communicationId,
                type: 'VALID'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsUnRecognisedList = resp.data.d.c
                    this.totalLocationsUnRecognised = resp.data.d.t
                }
            })
        },

        downloadFile(type) {
            supplierRecallService.downloadAffectedLocations({
                communicationId: this.communicationId,
                type
            }).then(resp => {
                if (!resp.error) {
                    window.open(resp.data.d, '_blank')
                }
            })
        },
        downloadAffectedLocationsXlxs(type) {
            supplierRecallService.getRecallById(this.communicationId).then(resp => {
                if (!resp.error) {
                    supplierRecallService.downloadAffectedLocationsXlxs({
                        communicationId: this.communicationId,
                        type
                    },resp.data.d.communicationUID).then(resp => {
                        if (!resp.error) {
                            window.open(resp.data.d, '_blank')
                        }
                    })
                }
            })

        }
    }
}